function isObject(data) {
    return Object.prototype.toString.call(data).slice(8, -1) === 'Object';
}

function isEmptyObject(data) {
    if (!isObject(data)) {
        return true;
    }
    
    return Object.keys(data).length === 0;
}

function isFunction(data) {
    return Object.prototype.toString.call(data).slice(8, -1) === 'Function';
}

function isBoolean(data) {
    return Object.prototype.toString.call(data).slice(8, -1) === 'Boolean';
}

function isString(data) {
    return Object.prototype.toString.call(data).slice(8, -1) === 'String';
}

function isArray(data) {
    return Object.prototype.toString.call(data).slice(8, -1) === 'Array';
}

function isArrayBuffer(data) {
    return Object.prototype.toString.call(data).slice(8, -1) === 'ArrayBuffer';
}

function removeLettersFromNumber(value) {
    return value
        .toString()
        .replace(/[^.\d]/g, '');
}

function replaceError(key, value) {
    if (value instanceof Error) {
        const newValue = Object.getOwnPropertyNames(value)
            .reduce((obj, propName) => {
                obj[propName] = value[propName];
                return obj;
            }, { name: value.name });
        return newValue;
    }
    else {
        return value;
    }
}

function getTextFromArrayBuffer(data) {
    return new Promise((resolve, reject) => {
        try {
            const blob = new Blob([ data ], { type: 'application/octet-stream' });
            const reader = new FileReader();
            
            reader.onload = event => {
                const errorMessage = JSON.parse(event.target.result);
                resolve(errorMessage.message);
            };
            
            reader.onerror = () => {
                reject(null);
            };
            
            reader.readAsText(blob);
        }
        catch (e) {
            reject(null);
        }
    });
}

function isValidLink(link) {
    return /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(link);
}

/**
 * Получить правильное склонение слова "день"
 * @param {Number} days
 */
function getDaysText(days) {
    try {
        const daysTemp = days % 10;
        const daysTemp_1 = days % 100;
        
        if ([ 11, 12, 13, 14 ].includes(daysTemp_1)) return 'дней';
        
        switch (daysTemp) {
            case 1:
                return 'день';
            case 2:
            case 3:
            case 4:
                return 'дня';
            default:
                return 'дней';
        }
    }
    catch (e) {
        return 'дней';
    }
}

export default {
    isObject:                isObject,
    isEmptyObject:           isEmptyObject,
    isFunction:              isFunction,
    isBoolean:               isBoolean,
    isString:                isString,
    isArray:                 isArray,
    isArrayBuffer:           isArrayBuffer,
    getTextFromArrayBuffer:  getTextFromArrayBuffer,
    replaceError:            replaceError,
    removeLettersFromNumber: removeLettersFromNumber,
    isValidLink:             isValidLink,
    getDaysText:             getDaysText,
};
