import {
    createRouter,
    createWebHistory,
} from '@ionic/vue-router';
import { StorageKeys } from '../scripts/storage_keys';
import helpers from '../scripts/helpers';

const routes = [
    {
        path:     '/',
        redirect: '/info/',
    },
    {
        path:      '/info/',
        component: () => import('@/layouts/MainTabsLayout.vue'),
        children:  [
            {
                path:     '',
                redirect: '/info/places',
            },
            {
                path:      'places',
                component: () => import('@/pages/Tabs/Places/Index.vue'),
                meta:      {
                    title: 'Карта | Достопримечательности',
                },
            },
            {
                path:      'routes',
                component: () => import('@/pages/Tabs/Routes/Index.vue'),
                meta:      {
                    title: 'Карта | Маршруты',
                },
            },
        ],
    },
    {
        path:      '/admin/',
        component: () => import('@/layouts/MainLayoutAdmin.vue'),
        children:  [
            {
                path:     '',
                redirect: '/admin/points',
            },
            {
                path:      'login',
                name:      'admin_login',
                component: () => import('@/pages/Admin/AdminLogin.vue'),
                meta:      {
                    title: 'Карта | Панель администратора – авторизация',
                },
            },
            {
                path:      'points',
                name:      'admin_points',
                component: () => import('@/pages/Admin/AdminPoints.vue'),
                meta:      {
                    title: 'Карта | Панель администратора – Достопримечательности',
                },
            },
            {
                path:      'tracks',
                name:      'admin_tracks',
                component: () => import('@/pages/Admin/AdminTracks.vue'),
                meta:      {
                    title: 'Карта | Панель администратора – Маршруты',
                },
            },
            {
                path:      'events',
                name:      'admin_events',
                component: () => import('@/pages/Admin/AdminEvents.vue'),
                meta:      {
                    title: 'Карта | Панель администратора – События',
                },
            },
        ],
    },
    {
        path:      '/c77e8ed8fe66bb945c424fecdfcc71b7aa447a5080f6c66b350b5b63da373b8570c97754921ea135283b238dc572ee18946f569bb49f4cb7e6f834ddc8cf4e41',
        component: () => import('@/layouts/MainCommentsLayout.vue'),
        children:  [
            {
                path:     '',
                redirect:  '/c77e8ed8fe66bb945c424fecdfcc71b7aa447a5080f6c66b350b5b63da373b8570c97754921ea135283b238dc572ee18946f569bb49f4cb7e6f834ddc8cf4e41/routes'
            },
            {
                path:     'routes',
                name:      'admin_routes',
                component: () => import('@/pages/Admin/Routes/Index.vue'),
                meta:  {
                    title: 'Карта | Панель администратора - Маршруты',
                }
            },
            {
                path:     'comments',
                name:      'admin_comments',
                component: () => import('@/pages/Admin/Comments/Index.vue'),
                meta:  {
                    title: 'Карта | Панель администратора - Комментарии',
                }
            },
        ],
    },
    
    // {
    //     path:      process.env.FRONT_API_ROUTE + '/403',
    //     name:      '403',
    //     component: import('pages/Error403.vue'),
    //     props:     true,
    //     meta:      {
    //         title: 'ВДело — 403',
    //     },
    // },
    
    // Always leave this as last one,
    // but you can also remove it
    {
        path:      '/:catchAll(.*)*',
        component: () => import('@/pages/Error404.vue'),
    },
];

const Router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

const token = JSON.parse(localStorage.getItem(StorageKeys.TOKEN_DATA)) || {};
let isAuthorized = false;

if (!helpers.isEmptyObject(token)) {
    const userRole = localStorage.getItem(StorageKeys.USER_ROLE);
    isAuthorized = userRole && Object.keys(token).includes(userRole) && !!token[userRole].access;
}

Router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    
    try {
        if (!isAuthorized && /admin(?!\/login)/.test(to.path)) {
            next({ name: 'admin_login' });
        }
        else if (isAuthorized && /admin\/login$/.test(to.path)) {
            next({ path: 'admin' });
        }
        else {
            next();
        }
    }
    catch (err) {
        console.error('ERROR vue router before each');
        console.error(err);
    }
});

export {
    Router,
    token,
    isAuthorized,
};
