const path = require('path');
require('dotenv').config({ path: path.join(__dirname + '../.env') });
import { createApp } from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import App from './App.vue';
import {
    Router,
    isAuthorized,
} from './router/index';
import axios from 'axios';

import {
    IonicVue,
    isPlatform,
    IonButton,
    IonText,
    IonList,
    IonLabel,
    IonItem,
    IonSpinner,
    IonRippleEffect,
    IonModal,
    IonRow,
    IonCol,
    IonGrid,
    IonPage,
    IonTextarea,
    IonInput,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonFooter,
    IonMenu,
    IonMenuButton,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
} from '@ionic/vue';

import { addIcons } from 'ionicons';
import {
    close,
    informationCircle,
    triangle,
    ellipse,
    analytics,
    chatbubbles,
    prism,
    calendar,
    documentAttach,
    help,
} from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import '../src/css/app.css';

/* Theme variables */
import './theme/variables.css';

const getConfig = () => {
    let config = {
        animated: false,
        menuIcon: 'ellipsis-vertical',
    };
    
    if (isPlatform('hybrid')) {
        return {
            ...config,
            backButtonText:  'Previous',
            tabButtonLayout: 'label-hide',
        };
    }
    
    if (isPlatform('iphone')) {
        config.backButtonText = 'Previous';
    }
    
    return config;
};

const app = createApp(App)
    .use(IonicVue, getConfig())
    .use(VueToast)
    .use(Router);

app.component('ion-button', IonButton);
app.component('ion-text', IonText);
app.component('ion-list', IonList);
app.component('ion-label', IonLabel);
app.component('ion-item', IonItem);
app.component('ion-spinner', IonSpinner);
app.component('ion-ripple-effect', IonRippleEffect);
app.component('ion-modal', IonModal);
app.component('ion-row', IonRow);
app.component('ion-col', IonCol);
app.component('ion-grid', IonGrid);
app.component('ion-textarea', IonTextarea);
app.component('ion-input', IonInput);
app.component('ion-content', IonContent);
app.component('ion-header', IonHeader);
app.component('ion-title', IonTitle);
app.component('ion-toolbar', IonToolbar);
app.component('ion-icon', IonIcon);
app.component('ion-card-content', IonCardContent);
app.component('ion-card-subtitle', IonCardSubtitle);
app.component('ion-card-title', IonCardTitle);
app.component('ion-card-header', IonCardHeader);
app.component('ion-footer', IonFooter);
app.component('ion-router-outlet', IonRouterOutlet);
app.component('ion-page', IonPage);
app.component('ion-menu', IonMenu);
app.component('ion-menu-button', IonMenuButton);
app.component('ion-tab-bar', IonTabBar);
app.component('ion-tab-button', IonTabButton);
app.component('ion-tabs', IonTabs);

app.config.globalProperties.$success = (message) => {
    app.config.globalProperties.$toast.open({
        message:      message,
        type:         'success',
        duration:     7000,
        position:     'top-right',
        pauseOnHover: false,
    });
};

app.config.globalProperties.$error = (message) => {
    app.config.globalProperties.$toast.open({
        message:      message,
        type:         'error',
        duration:     7000,
        position:     'top-right',
        pauseOnHover: false,
    });
};

app.config.globalProperties.$isMobile = window.screen.width < 1024;

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = window.location.origin ? window.location.origin : 'https://map.mkurala.ru/';
}

app.config.globalProperties.$axios = axios;
app.provide('isAuthorized', isAuthorized);
app.provide('neededOrganizations', [
    {
        name:     'ИП Быков Александр Николаевич',
        coords:   [ 57.730116, 59.878457 ],
        director: 'Быков Александр Николаевич',
        manager:  'Кирилюк Елена Александровна',
        website:  null,
        emails:   [ 'kirilyuk_elena@list.ru', 'vodoley1365@mail.ru' ],
        socials:  [
            { vk: 'https://vk.com/event204815708' },
            { inst: 'https://vk.com/event204815708' },
        ],
        picture:  '../assets/images/865.jpg',
    },
    {
        name:     'ООО «МОРСЕль»',
        coords:   [ 57.802763, 60.055067 ],
        director: 'Ибрагимова Марина Геннадьевна',
        manager:  null,
        website:  'https://morsel-nt.ru',
        emails:   [ 'stms@morsel-nt.ru' ],
        socials:  [
            { vk: 'https://vk.com/morselnt' },
        ],
        picture:  '../assets/images/30258560061424786_702d_600x600.jpg',
    },
    {
        name:     'ООО «Всеобуч-НТ»',
        coords:   [ 57.802042, 60.060938 ],
        director: 'Ибрагимова Марина Геннадьевна',
        manager:  null,
        website:  'https://ermakgorod.ru',
        emails:   [ 'Morsel-nt@bk.ru' ],
        socials:  [],
        picture:  '../assets/images/PHOTO-2021-11-23-12-00-46.jpg',
    },
]);

Router.isReady().then(() => {
    app.mount('#app');
});

addIcons({
    'close':              close,
    'triangle':           triangle,
    'ellipse':            ellipse,
    'analytics':          analytics,
    'chatbubbles':        chatbubbles,
    'prism':              prism,
    'calendar':           calendar,
    'information-circle': informationCircle,
    'document':           documentAttach,
    'help':               help,
});
