<template>
    <ion-app>
        <ion-router-outlet/>
    </ion-app>
</template>

<script>
import {
    IonApp,
    IonRouterOutlet,
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name:       'App',
    components: {
        IonApp,
        IonRouterOutlet,
    },
});
</script>

<style>
.label-default {
    color: #838383 !important;
    font-weight: bold;
}

.text-danger-label {
    color: orangered !important;
    font-weight: bold;
}

.text-danger {
    color: orangered !important;
}

.text-wrapper {
    min-height: 30px !important;
    height: 30px !important;
    max-height: 60px !important
}

/*ion-icon {*/
/*    color: black;*/
/*}*/

.textarea-wrapper.sc-ion-textarea-md {
    height: 40px;
}

.native-textarea.sc-ion-textarea-md {
    min-height: 40px !important;
}

.full {
    width: 100%;
    height: 100%;
}

.loader {
    left: 50%;
    top: 50%;
}

.map-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    max-height: 100%;
}

.map-container > .map-list {
    display: none;
    flex-direction: column;
    max-height: 100%;
}

.map-container > .map-list > div.items {
    flex: 1 1 auto;
    overflow-y: scroll;
}

.map-container > .map-list > ion-list {
}

.map-container > .map {
    width: 100%;
    max-height: 100%;
}

@media (min-width: 1536px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1024px) {
    .modal-wrapper.sc-ion-modal-md {
        --width: 75vw;
        --height: 75vh;
    }
    
    .map-container > .map-list {
        display: flex;
        width: 30%;
    }
    
    .map-container > .map {
        width: 70%;
    }
}

@media (min-width: 768px) {
}

@media (min-width: 640px) {

}

.buttonAddWrapper {
    z-index: 1;
    position: relative;
    box-shadow: -5px -2px 20px rgba(0, 0, 0, 0.2);
}

.buttonAddWrapper > .buttonAdd {
    box-shadow: none;
}

.buttonAddWrapper > .buttonAdd:last-child {
    box-shadow: none;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.buttonAdd {
    text-align: center;
    cursor: pointer;
    padding: 1rem 0.5rem;
    box-shadow: -5px -2px 20px rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: relative;
    background-color: #99DDFF48;
}

/*.buttonAdd:last-child {*/
/*    box-shadow: -5px -2px 20px rgba(0, 0, 0, 0.05);*/
/*}*/

.buttonAdd::before {
    transform: scaleX(0);
    transform-origin: bottom right;
}

.buttonAdd:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.buttonAdd::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0 0 0 0;
    background: hsl(200 100% 80%);
    z-index: -1;
    transition: transform .3s ease;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}

.add-point-confirm-close .alert-wrapper {
    width: 450px;
    max-width: 90vw;
}

@keyframes shine {
    from {
        background-position: 200% center;
    }
}
</style>
